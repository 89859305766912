import { AxiosInstance } from 'axios';
import { FindAddressModel } from '../models/system/FindAddressModel';
import { apiClient } from '../utils/api';
import { StorageKey } from '../constants/StorageKey';
import { storage } from '../utils/storage';

class SystemService {
    constructor(
        private apiClient: AxiosInstance,
    ) {}

    public getCountries = async (): Promise<Record<string, string>> => {
        const response = await this.apiClient.get<{ data: Record<string, string> }>('/system/countries');
        return response.data.data;
    };

    public findAddress = async (postCode: string, houseNumberName?: string): Promise<FindAddressModel[]> => {
        const token = storage.get(StorageKey.BORROWER_TOKEN);
        const response = await this.apiClient.get<{ data: FindAddressModel[] }>(
            '/system/find-address',
            {
                headers: {
                    'authorization': token && `bearer ${token}`,
                },
                params: { postCode, houseNumberName },
            },
        );

        return response.data.data;
    };
}

export const systemService = new SystemService(apiClient);
