import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Divider, FormControlLabel, FormHelperText, IconButton, InputAdornment, Link, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { Delete as DeleteIcon, ExpandMore as ExpandMoreIcon, Search as SearchIcon } from "@mui/icons-material";
import { ChangeEvent, FC, Fragment, MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getIn, useFormikContext } from "formik";
import { PropertyType } from "../../../../constants/PropertyType";
import { FindAddressModel } from "../../../../models/system/FindAddressModel";
import { getCountries } from "../../../../store/system/SystemSelectors";
import { systemService } from "../../../../services/systemService";
import { POSTAL_CODE_GB } from "../../../../constants/RegExp";
import { NumberInput } from "../../../../components/customInputs/NumberInput";
import { FindAddressDialog } from "../../../Common/FindAddressDialog";
import * as Yup from 'yup';
import { TFunction } from "i18next";
import { SystemActions } from "../../../../store/system/SystemActions";
import { config } from "../../../../config";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    section: {
        '&:not(:first-child)': {
            marginTop: theme.spacing(4),
        },
    },
    accordion: {
        maxWidth: theme.spacing(68),
    },
    accordionSummaryContent: {
        alignItems: 'center',
        margin: 0,
        '&.Mui-expanded': {
            margin: 0,
        },
    },
    deleteAction: {
        marginLeft: 'auto',
    },
    accordionDetails: {
        flexDirection: 'column',
    },
    addPropertyAction: {
        marginTop: theme.spacing(2),
    },
}));

interface Values {
    properties: Array<{
        isFirstCharge: string;
        otherCharges: string;
        addressPostalCode: string;
        addressLineOne: string;
        addressLineTwo: string;
        addressCity: string;
        addressRegion: string;
        addressCountry: string;
        propertyType: string;
        propertyTypeOther: string;
        isVacant: string;
        description: string;
        value: string;
    }>;
}

export const propertiesValidationSchema = (t: TFunction) => Yup.object().shape({
    properties: Yup.array().of(Yup.object().shape({
        isFirstCharge: Yup.boolean().required(t('Please select primary security charge')),
        otherCharges: Yup.number().when('isFirstCharge', { is: false, then: schema => schema.required(t('Please enter other charges value')) }),
        addressPostalCode: Yup.string().required(t('Please enter your address postal code')),
        addressLineOne: Yup.string().required(t('Please enter your address line 1')),
        addressLineTwo: Yup.string().optional(),
        addressCity: Yup.string().required(t('Please enter your address city')),
        addressRegion: Yup.string().required(t('Please enter your address region')),
        addressCountry: Yup.string().required(t('Please enter your address country')),
        propertyType: Yup.string().oneOf(['residential', 'commercial', 'mixed', 'residential-buy-to-let', 'other']).required(t('Please select property type')),
        propertyTypeOther: Yup.string().when('propertyType', { is: 'other', then: schema => schema.required(t('Please enter property type')) }),
        isVacant: Yup.boolean().required(t('Please select if the property currently vacant')),
        description: Yup.string().required(t('Please provide a description of the property'))
            .max(1000, (options) => t('Description can not be longer than {{max}} symbols', options)),
        value: Yup.number()
            .required(t('Please enter estimate value of the property'))
            .min(
                config.loanApplication.minimumAssetValue,
                (options) => t('The minimum acceptable property value is {{min, currency(currency: GBP; maximumFractionDigits: 0)}}', options),
            ),
    })),
});

export const propertiesInitialValues = {
    properties: [
        {
            isFirstCharge: '',
            otherCharges: '',
            addressPostalCode: '',
            addressLineOne: '',
            addressLineTwo: '',
            addressCity: '',
            addressRegion: '',
            addressCountry: '',
            propertyType: '',
            propertyTypeOther: '',
            isVacant: '',
            description: '',
            value: '',
        },
    ],
};

interface PropertyTypeItem {
    value: PropertyType;
    label: string;
}

interface PropertiesStepProps {
    isRegistered?: boolean;
}

export const PropertiesStep: FC<PropertiesStepProps> = ({ isRegistered }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const formik = useFormikContext<Values>();

    const [expandedAccordion, setExpandedAccordion] = useState<number|null>(0);
    const [findAddressDialogOpen, setFindAddressDialogOpen] = useState(false);
    const [addressesLoading, setAddressesLoading] = useState(false);
    const [foundAddresses, setFoundAddresses] = useState<FindAddressModel[]>([]);

    const countries = useSelector(getCountries);

    useEffect(() => {
        if (countries.length === 0) {
            dispatch(SystemActions.getCountries());
        }
    }, [dispatch, countries.length]);

    const propertyTypes: PropertyTypeItem[] = [
        {
            value: PropertyType.Residential,
            label: t('Residential'),
        },
        {
            value: PropertyType.Commercial,
            label: t('Commercial'),
        },
        {
            value: PropertyType.Mixed,
            label: t('Mixed'),
        },
        {
            value: PropertyType.ResidentialBuyToLet,
            label: t('Residential buy-to-let'),
        },
    ];

    const handlePostCodeSearchClick = (propertyIndex: number) => async () => {
        setAddressesLoading(true);
        setFindAddressDialogOpen(true);

        try {
            setFoundAddresses(await systemService.findAddress(formik.values.properties[propertyIndex].addressPostalCode));
        } catch (e) {
            console.error('Error finding address');
            setFoundAddresses([]);
        }

        setAddressesLoading(false);
    };

    const handleFindAddressSelect = (propertyIndex: number) => (address: FindAddressModel) => {
        void formik.setFieldValue(`properties[${propertyIndex}].addressLineOne`, address.lineOne);
        void formik.setFieldValue(`properties[${propertyIndex}].addressLineTwo`, address.lineTwo);
        void formik.setFieldValue(`properties[${propertyIndex}].addressCity`, address.townCity);
        void formik.setFieldValue(`properties[${propertyIndex}].addressRegion`, address.region);
        void formik.setFieldValue(`properties[${propertyIndex}].addressCountry`, 'GB');
        setFindAddressDialogOpen(false);
    };

    const handleIsFirstChargeChange = (propertyIndex: number) => (e: ChangeEvent<HTMLInputElement>) => {
        formik.handleChange(e);

        if (e.target.value === 'true') {
            void formik.setFieldValue(`properties[${propertyIndex}].otherCharges`, '');
        }
    };

    const handlePropertyTypeChange = (propertyIndex: number) => (e: ChangeEvent<HTMLInputElement>) => {
        formik.handleChange(e);
        void formik.setFieldValue(`properties[${propertyIndex}].propertyTypeOther`, '');
    };

    const handlePostalCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
        void formik.setFieldValue(e.target.name, e.target.value.toUpperCase());
    };

    const renderPropertyForm = (propertyIndex: number) => {
        const fieldName = (name: keyof Values['properties'][number]) => `properties[${propertyIndex}].${name}`;
        const fieldValue = (name: keyof Values['properties'][number]) => formik.values.properties[propertyIndex][name];
        const fieldTouched = (name: keyof Values['properties'][number]) => getIn(formik.touched, fieldName(name)) as boolean;
        const fieldError = (name: keyof Values['properties'][number]) => getIn(formik.errors, fieldName(name)) as string;

        const isUkPostalCode = POSTAL_CODE_GB.exec(fieldValue('addressPostalCode')) !== null;
        const postalCodeInputAdornment = isRegistered
            ? <InputAdornment position="end">
                <IconButton
                    onClick={handlePostCodeSearchClick(propertyIndex)}
                    disabled={!isUkPostalCode}
                    title={t('Lookup for GB address')}
                    size="large"
                >
                    <SearchIcon />
                </IconButton>
            </InputAdornment>
            : null;

        return <Fragment>
            <Box className={classes.section}>
                <Typography gutterBottom variant="h5">
                    <b>{t('Is this a 1st or 2nd charge?')}</b>
                </Typography>
                <RadioGroup
                    name={fieldName('isFirstCharge')}
                    value={fieldValue('isFirstCharge')}
                    onChange={handleIsFirstChargeChange(propertyIndex)}
                    onBlur={formik.handleBlur}
                    row
                >
                    <FormControlLabel
                        value="true"
                        control={<Radio color="primary" />}
                        label={t('1st Charge')}
                    />
                    <FormControlLabel
                        value="false"
                        control={<Radio color="primary" />}
                        label={t('2nd Charge')}
                    />
                </RadioGroup>
                <FormHelperText error={fieldTouched('isFirstCharge') && Boolean(fieldError('isFirstCharge'))}>
                    {fieldTouched('isFirstCharge') && fieldError('isFirstCharge')}
                </FormHelperText>
                { fieldValue('isFirstCharge') === 'false' &&
                    <NumberInput
                        decimalScale={2}
                        allowNegative={false}
                        thousandSeparator=","
                        valueIsNumericString
                        prefix="£"
                        fullWidth
                        name={fieldName('otherCharges')}
                        label={t('Enter first charge value')}
                        placeholder={t('Please enter the amount of all other charges')}
                        value={fieldValue('otherCharges')}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        error={fieldTouched('otherCharges') && Boolean(fieldError('otherCharges'))}
                        helperText={fieldTouched('otherCharges') && fieldError('otherCharges')}
                    />
                }
            </Box>

            <Box className={classes.section}>
                <Typography gutterBottom variant="h5">
                    <b>{t('What is the address of the UK property?')}</b>
                </Typography>
                <TextField
                    fullWidth
                    name={fieldName('addressPostalCode')}
                    label={t('Post Code')}
                    value={fieldValue('addressPostalCode')}
                    onChange={handlePostalCodeChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    slotProps={{
                        input: {
                            endAdornment: postalCodeInputAdornment,
                        },
                    }}
                    error={fieldTouched('addressPostalCode') && Boolean(fieldError('addressPostalCode'))}
                    helperText={fieldTouched('addressPostalCode') && fieldError('addressPostalCode')}
                />
                <TextField
                    fullWidth
                    name={fieldName('addressLineOne')}
                    label={t('Address Line 1')}
                    value={fieldValue('addressLineOne')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    error={fieldTouched('addressLineOne') && Boolean(fieldError('addressLineOne'))}
                    helperText={fieldTouched('addressLineOne') && fieldError('addressLineOne')}
                />
                <TextField
                    fullWidth
                    name={fieldName('addressLineTwo')}
                    label={t('Address Line 2')}
                    value={fieldValue('addressLineTwo')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    error={fieldTouched('addressLineTwo') && Boolean(fieldError('addressLineTwo'))}
                    helperText={fieldTouched('addressLineTwo') && fieldError('addressLineTwo')}
                />
                <TextField
                    fullWidth
                    name={fieldName('addressCity')}
                    label={t('Town / City')}
                    value={fieldValue('addressCity')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    error={fieldTouched('addressCity') && Boolean(fieldError('addressCity'))}
                    helperText={fieldTouched('addressCity') && fieldError('addressCity')}
                />
                <TextField
                    fullWidth
                    name={fieldName('addressRegion')}
                    label={t('County / Region')}
                    value={fieldValue('addressRegion')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    error={fieldTouched('addressRegion') && Boolean(fieldError('addressRegion'))}
                    helperText={fieldTouched('addressRegion') && fieldError('addressRegion')}
                />
                <Autocomplete
                    autoHighlight
                    fullWidth
                    disableClearable
                    options={countries}
                    value={countries.find(item => item.code === fieldValue('addressCountry')) || { code: '', name: '' }}
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                    getOptionLabel={option => option.name}
                    onChange={(e, option) => formik.setFieldValue(fieldName('addressCountry'), option?.code || '')}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('Country')}
                            placeholder={t('Please select country')}
                            margin="dense"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            error={fieldTouched('addressCountry') && Boolean(fieldError('addressCountry'))}
                            helperText={fieldTouched('addressCountry') && fieldError('addressCountry')}
                        />
                    )}
                />
            </Box>

            <Box className={classes.section}>
                <Typography gutterBottom variant="h5">
                    <b>{t('What is the type of property?')}</b>
                </Typography>
                <RadioGroup
                    name={fieldName('propertyType')}
                    value={fieldValue('propertyType')}
                    onChange={handlePropertyTypeChange(propertyIndex)}
                    onBlur={formik.handleBlur}
                >
                    {propertyTypes.map(
                        item => <FormControlLabel
                            key={item.value}
                            value={item.value}
                            control={<Radio color="primary" />}
                            label={item.label}
                        />,
                    )}
                    <FormControlLabel
                        value={PropertyType.Other}
                        control={<Radio color="primary" />}
                        label={t('Other')}
                    />
                    { fieldValue('propertyType') === PropertyType.Other &&
                        <TextField
                            fullWidth
                            name={fieldName('propertyTypeOther')}
                            label={t('Please specify')}
                            placeholder={t('Please enter the property type')}
                            value={fieldValue('propertyTypeOther')}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            margin="dense"
                            error={fieldTouched('propertyTypeOther') && Boolean(fieldError('propertyTypeOther'))}
                            helperText={fieldTouched('propertyTypeOther') && fieldError('propertyTypeOther')}
                        />
                    }
                </RadioGroup>
                <FormHelperText error={fieldTouched('propertyType') && Boolean(fieldError('propertyType'))}>
                    {fieldTouched('propertyType') && fieldError('propertyType')}
                </FormHelperText>
            </Box>

            <Box className={classes.section}>
                <Typography gutterBottom variant="h5">
                    <b>{t('Is the property currently vacant?')}</b>
                </Typography>
                <RadioGroup
                    name={fieldName('isVacant')}
                    value={fieldValue('isVacant')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    row
                >
                    <FormControlLabel
                        value="true"
                        control={<Radio color="primary" />}
                        label={t('Yes')}
                    />
                    <FormControlLabel
                        value="false"
                        control={<Radio color="primary" />}
                        label={t('No')}
                    />
                </RadioGroup>
                <FormHelperText error={fieldTouched('isVacant') && Boolean(fieldError('isVacant'))}>
                    {fieldTouched('isVacant') && fieldError('isVacant')}
                </FormHelperText>
            </Box>

            <Box className={classes.section}>
                <Typography gutterBottom variant="h5">
                    <b>{t('Please provide a short description of the property')}</b>
                </Typography>
                <TextField
                    fullWidth
                    multiline
                    name={fieldName('description')}
                    label={t('Description')}
                    placeholder={t('Please enter description')}
                    value={fieldValue('description')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    rows={5}
                    inputProps={{ maxLength: 1000 }}
                    error={fieldTouched('description') && Boolean(fieldError('description'))}
                    helperText={fieldTouched('description') && fieldError('description')}
                />
            </Box>

            <Box className={classes.section}>
                <Typography gutterBottom variant="h5">
                    <b>{t('Estimated value of property')}</b>
                </Typography>
                <NumberInput
                    decimalScale={2}
                    allowNegative={false}
                    thousandSeparator=","
                    valueIsNumericString
                    prefix="£"
                    fullWidth
                    name={fieldName('value')}
                    label={t('Value')}
                    placeholder={t('Please enter value')}
                    value={fieldValue('value')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    error={fieldTouched('value') && Boolean(fieldError('value'))}
                    helperText={fieldTouched('value') && fieldError('value')}
                />
            </Box>
        </Fragment>;
    };

    const handleAddProperty = () => {
        const newIndex = formik.values.properties.length;
        void formik.setFieldValue('properties', [...formik.values.properties, {
            isFirstCharge: '',
            otherCharges: '',
            addressPostalCode: '',
            addressLineOne: '',
            addressLineTwo: '',
            addressCity: '',
            addressRegion: '',
            addressCountry: '',
            propertyType: '',
            propertyTypeOther: '',
            isVacant: '',
            description: '',
            value: '',
        }]);
        setExpandedAccordion(newIndex);
    };

    const handleDeleteProperty = (propertyIndex: number) => (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        void formik.setFieldValue('properties', formik.values.properties.filter((item, index) => index !== propertyIndex));
    };

    const handleExpandedChange = (panelIndex: number) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
        setExpandedAccordion(isExpanded ? panelIndex : null);
    };

    return <Box>
        <Typography gutterBottom variant="h5">
            {t('Please provide details of the security being provided for the loan')}
        </Typography>

        <Box>
            {formik.values.properties.map((item, index) => (
                <Accordion
                    className={classes.accordion}
                    key={index}
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={index === expandedAccordion}
                    onChange={handleExpandedChange(index)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        classes={{
                            content: classes.accordionSummaryContent,
                        }}
                    >
                        <Typography>Property {index + 1}</Typography>
                        { formik.values.properties.length > 1 &&
                            <IconButton
                                className={classes.deleteAction}
                                onClick={handleDeleteProperty(index)}
                                size="large"
                            >
                                <DeleteIcon />
                            </IconButton>
                        }
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails className={classes.accordionDetails}>
                        {renderPropertyForm(index)}
                    </AccordionDetails>

                    <FindAddressDialog
                        isOpen={findAddressDialogOpen}
                        isLoading={addressesLoading}
                        onSelect={handleFindAddressSelect(index)}
                        onClose={() => setFindAddressDialogOpen(false)}
                        addresses={foundAddresses}
                    />
                </Accordion>
            ))}
        </Box>

        { formik.values.properties.length < 5 &&
            <Button component={Link} onClick={handleAddProperty} className={classes.addPropertyAction}>
                {t('+ Add an additional property')}
            </Button>
        }
    </Box>;
};
